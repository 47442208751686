import React from "react";


const Next = () => {

  return (
    <div className="container mx-auto px-4 py-5 flex flex-col flex-grow">
      Proximamente..
    </div>
  );
};

export default Next;
